export default function cookieconsent() {
  const cc = initCookieConsent()

  cc.run({
    autorun: true,
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,
    revision: 0,

    gui_options: {
      consent_modal: {
        layout: 'cloud',
        position: 'bottom center',
        transition: 'slide',
      },
      settings_modal: {
        layout: 'box',
        transition: 'slide',
      },
    },

    onAccept: function (cookie) {
      if (cc.allowedCategory('analytics')) {
        enableAnalytics()
      }
    },

    onChange: function (cookie, changed_preferences) {
      if (!cc.allowedCategory('analytics')) {
        disableAnalytics()
      }

      if (cc.allowedCategory('analytics')) {
        enableAnalytics()
      }
    },

    languages: {
      en: {
        consent_modal: {
          title: 'We use cookies!',
          description:
            'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary',
          },
        },
        settings_modal: {
          title: '',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            { col4: 'Description' },
          ],
          blocks: [
            {
              title: 'Cookies on COMEBACKSOONTRACKER.COM',
              description: `Cookies are files saved on your phone, tablet or computer when you visit a website.
                          <br />
                          <br />
                          We use cookies to collect and store information about how you use our website, such as what pages you visit.
                          <br />
                          <br />
                          Below you can see the cookies that we use and read what we use these cookies for. For details relative sensitive data, please read the full <a href="/privacy" class="cc-link">privacy policy</a>.`,
            },
            {
              title: 'Strictly necessary cookies',
              description: `These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly`,
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: 'Performance and Analytics cookies',
              description:
                'These cookies allow the website to remember the choices you have made in the past',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 years',
                  col4: 'These help us count how many people visit COMEBACKSOONTRACKER.COM by tracking if you’ve visited before',
                  is_regex: true,
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '24 hours',
                  col4: 'These help us count how many people visit COMEBACKSOONTRACKER.COM by tracking if you’ve visited before',
                },
              ],
            },
          ],
        },
      },
    },
  })
}

function enableAnalytics() {
  window.gtag?.('consent', 'update', {
    analytics_storage: 'granted',
  })
}

function disableAnalytics() {
  window.gtag?.('consent', 'update', {
    analytics_storage: 'denied',
  })
}
