import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'
import debounce from 'lodash/debounce'

export default class AutocompleteController extends Controller {
  static targets = ['input']

  static values = {
    items: [],
    sourceUrl: String,
    outputField: 'value',
    labelField: 'title',
    autoSubmit: {
      type: Boolean,
      default: false,
    },
  }

  loadingController = null
  tagify = null

  connect() {
    this.tagify = new Tagify(this.inputTarget, {
      whitelist: this.itemsValue,
      dropdown: {
        enabled: 1, // show suggestions dropdown after 1 typed character
        mapValueTo: 'title',
        searchKeys: ['value', 'title'],
      },
      tagTextProp: 'title',
      mode: 'select',
    })

    // Load suggestions from server
    if (this.sourceUrlValue) {
      this.tagify.on('input', debounce(this.onInput, 300))
    }

    // Find parent form and override submission
    this.inputTarget.addEventListener('change', this.handleChange)

    // Also update value before submit fires
    const form = this.element.closest('form')
    form.addEventListener('submit', this.updateValue)
  }

  disconnect() {
    this.tagify?.destroy()
    this.inputTarget.removeEventListener('change', this.handleChange)
    const form = this.element.closest('form')
    form.removeEventListener('submit', this.updateValue)
  }

  handleChange = (e) => {
    this.updateValue()
    if (this.autoSubmitValue) {
      this.submit()
    }
  }

  onInput = async (e) => {
    const value = e.detail.value
    this.tagify.whitelist = null

    try {
      this.loadingController && this.loadingController.abort()
      this.loadingController = new AbortController()

      // show loading animation and hide the suggestions dropdown
      this.tagify.loading(true).dropdown.hide()

      const response = await fetch(this.sourceUrlValue + '?q=' + value, {
        signal: this.loadingController.signal,
      })
      const json = await response.json()
      this.tagify.whitelist = json
      this.tagify.loading(false).dropdown.show(value)
    } catch (error) {
      if (error.name === 'AbortError') {
        return
      }
      console.error('Error ', error)
    }
  }

  updateValue = (e) => {
    const form = this.element.closest('form')
    if (!form || !form.isConnected) {
      return
    }

    if (!this.inputTarget.value) {
      return
    }

    const originalValue = this.inputTarget.value
    try {
      const json = JSON.parse(originalValue)
      this.inputTarget.value = json[0].value
    } catch (err) {
      // Error updating value, revert
      this.inputTarget.value = originalValue
    }
  }

  submit = (e) => {
    const form = this.element.closest('form')
    if (!form || !form.isConnected) {
      return
    }

    if (!this.tagify.state.hasFocus) {
      // Not triggered by user
      return
    }

    form.requestSubmit()
  }
}
